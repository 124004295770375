body {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

#pdf-container {
  visibility: hidden;
}

@media print {
  #pdf-container {
    visibility: visible;
  }
}
